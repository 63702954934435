<template>

    <section class="faqs-container">

        <h2 class="title">Preguntas frecuentes</h2>

        <div class="container contenedor-faqs row-between">

            <div class="column" v-if="show.includes('mentor')">
                <h3>Mentores</h3>
                <article class="faq" type="mentor">
                    <h2 @click="toggle">1. ¿Cómo me doy de alta como mentor/a? ¿Cuáles son los requisitos para inscribirme?</h2>
                    <div class="body">
                        <p>Debes ser egresado/a UC3M con una experiencia profesional igual o superior a 3 años. Para darte de alta debes ir al área de mentores e introducir tu DNI, NIE o pasaporte en el apartado <a href="https://gestionmentoring.uc3m.es/login/mentor">“Quiero ser
                                mentor/a”</a></p>
                    </div>
                </article>
                <article class="faq" type="mentor">
                    <h2 @click="toggle">2. ¿Cómo es el proceso de asignación de mi mentee? ¿Cómo contacto con mi mentee?
                    </h2>
                    <div class="body">
                        <p>El mentee será quien seleccione a un mentor/a disponible (hace match). En ese momento, el mentor/a recibirá un email con los datos personales y de contacto del mentee para iniciar la mentoría y mantener las tres reuniones mínimas obligatorias.</p>
                        <p>Podrás ser seleccionado/a hasta por tres mentees durante el curso académico (período comprendido entre el 1 de septiembre y el 31 de agosto del año siguiente), pero simultáneamente solo podrás tener dos mentorías activas.</p>
                    </div>
                </article>
                <article class="faq" type="mentor">
                    <h2 @click="toggle">3. ¿Cuántas reuniones o sesiones mínimas debo tener con mi mentee? ¿Qué duración tendrá la mentoría?</h2>
                    <div class="body">
                        <p>Las sesiones mínimas serán tres por cada mentee y podrán ser online y/o presenciales. La duración máxima por mentoría será de tres meses.</p>
                    </div>
                </article>
                <article class="faq" type="mentor">
                    <h2 @click="toggle">4. ¿Cuántos mentees puedo tener simultáneamente?</h2>
                    <div class="body">
                        <p>Como máximo dos mentees simultáneamente, aunque a lo largo del curso académico (período comprendido del 1 de septiembre al 31 de agosto del año siguiente) podrás ser seleccionado/a hasta por un máximo de tres mentees.</p>
                    </div>
                </article>
                <article class="faq" type="mentor">
                    <h2 @click="toggle">5. ¿Cómo accedo al certificado de participación?</h2>
                    <div class="body">
                        <p>Podrán obtener este certificado, tras finalizar la mentoría, quienes hayan realizado las tres sesiones mínimas obligatorias de la mentoría y hayan cumplimentado la
                            <strong>evaluación</strong> correspondiente.
                        </p>
                        <p>Este certificado reconoce la participación del mentor/a en el Programa y podrá descargarse como un diploma de participación en formato PDF desde tu área privada del participante, apartado <a href="https://gestionmentoring.uc3m.es/mis-mentorias" target="_blank">“Mis mentorías”</a>.</p>
                    </div>
                </article>
                <article class="faq" type="mentor">
                    <h2 @click="toggle">6. Una vez que finalice el período de mentoría, ¿qué debo hacer?</h2>
                    <div class="body">
                        <p>Es importante que cumplimentes la <strong>evaluación</strong> de la mentoría una vez que haya finalizado. Para nosotros es muy importante conocer tu grado de satisfacción con el Programa y saber en qué podemos mejorar. Puedes acceder a dicha evaluación desde tu área privada, apartado <a href="https://gestionmentoring.uc3m.es/mis-mentorias" target="_blank">“Mis mentorías”</a> y seleccionando la mentoría finalizada.</p>
                    </div>
                </article>
                <article class="faq" type="mentor">
                    <h2 @click="toggle">7. Si tengo algún problema durante la mentoría ¿a quién debo acudir?</h2>
                    <div class="body">
                        <p>Podrás comunicarnos cualquier incidencia por el <router-link :to="$t('enlaces.contacto')">formulario de contacto </router-link> o desde tu área privada de mentores, apartado <a href="https://gestionmentoring.uc3m.es/mis-mentorias" target="_blank">“Mis mentorías”</a>.</p>
                    </div>
                </article>
                <article class="faq" type="mentor">
                    <h2 @click="toggle">8. ¿Cómo puedo acceder a la Red de Mentores para conocer otros profesionales UC3M y crear sinergias?</h2>
                    <div class="body">
                        <p>Accede a tu área privada de mentores y consulta el apartado <a href="https://gestionmentoring.uc3m.es/red-de-mentores" target="_blank">“Red de mentores”</a>.
                            Si quieres estar visible en la Red de Mentores para otros mentores participantes, consultar sus perfiles profesionales, generar sinergias y fortalecer tus vínculos UC3M, puedes activar esta configuración desde el apartado <a href="https://gestionmentoring.uc3m.es/mi-perfil" target="_blank">“Mi perfil”</a> de tu área privada.</p>
                    </div>
                </article>

            </div>

            <div class="column" v-if="show.includes('mentee')">
                <h3>Mentees</h3>
                <article class="faq" type="mentee">
                    <h2 @click="toggle">1. ¿Cómo me doy de alta como mentee? ¿Cuáles son los requisitos para inscribirme? </h2>
                    <div class="body">
                        <p>Debes pertenecer al siguiente colectivo UC3M: ser estudiante de Grado, de tercer curso o superior, o estudiante de postgrado o egresado/a. Para darte de alta debes ir al área de mentees e introducir tu DNI, NIE o pasaporte en el apartado <a href="https://gestionmentoring.uc3m.es/login/mentee" target="_blank">“Quiero ser mentee”</a>. </p>
                        <p>Es importante que tengas disponibilidad para cumplir con un mínimo de 3 sesiones en un máximo de 3 meses.</p>
                    </div>
                </article>

                <article class="faq" type="mentee">
                    <h2 @click="toggle">2. ¿Cómo selecciono a un mentor/a (match)?</h2>
                    <div class="body">
                        <p>Accede a tu área de mentee, apartado <a href="https://gestionmentoring.uc3m.es/encuentra-un-mentor" target="_blank">“Encuentra un mentor/a”</a>. Podrás ampliar o reducir la búsqueda de mentores disponibles aplicando diferentes filtros para encontrar al mentor/a que se adapte a tus preferencias y hacer match.</p>
                    </div>
                </article>

                <article class="faq" type="mentee">
                    <h2 @click="toggle">3. ¿Cómo contacto con mi mentor/a? ¿Qué debo hacer si el mentor/a no contacta conmigo?</h2>
                    <div class="body">
                        <p>Una vez has seleccionado a tu mentor/a (haces match), será tu mentor/a quien reciba un email con tus datos personales para iniciar el contacto. Por lo tanto, deberás mantenerte a la espera.</p>
                        <p>Recibirás un email para que confirmes si el mentor/a se ha puesto en contacto contigo. No obstante, puedes comunicarnos cualquier incidencia desde tu área privada de mentee o por el <router-link :to="$t('enlaces.contacto')">formulario de contacto </router-link> </p>
                    </div>
                </article>

                <article class="faq" type="mentee">
                    <h2 @click="toggle">4. ¿Qué debo hacer cuando el mentor/a contacte conmigo? Si tengo algún problema durante la mentoría ¿a quién debo acudir?</h2>
                    <div class="body">
                        <p>Muéstrate proactivo/a y reflexiona previamente sobre tus intereses profesionales. Es importante también que tengas disponibilidad para cumplir con un mínimo de 3 sesiones en un máximo de 3 meses. </p>
                        <p>No te olvides <strong>activar la mentoría cuando el mentor/a contacte contigo</strong> desde
                            tu área privada de mentee, apartado <a href="https://gestionmentoring.uc3m.es/mis-mentorias" target="_blank">“Mis mentorías”</a>.</p>
                        <img src="/img/boton-contactar.png" alt="Confirmar contacto mentor">
                        <p>Si durante la mentoría tienes algún problema podrás comunicarnos cualquier incidencia por el <router-link :to="$t('enlaces.contacto')">formulario de contacto </router-link>o desde tu área privada de mentee, apartado <a href="https://gestionmentoring.uc3m.es/mis-mentorias" target="_blank">“Mis mentorías”</a></p>
                    </div>
                </article>

                <article class="faq" type="mentee">
                    <h2 @click="toggle">5. ¿Cuántos mentores puedo tener simultáneamente?</h2>
                    <div class="body">
                        <p>Solo se permite tener una mentoría activa, es decir, no podrás tener varios mentores simultáneamente. Una vez que la mentoría ha llegado a su fin puedes volver a solicitar otro mentor/a en el siguiente curso académico o bien en cualquiera de los programas activos de Mentoring que podrás consultar en tu área privada, apartado <a href="https://gestionmentoring.uc3m.es/programas" target="_blank">“Programas”</a>.</p>
                    </div>
                </article>


                <article class="faq" type="mentee">
                    <h2 @click="toggle">6. ¿Cuántas reuniones o sesiones mínimas debo tener con mi mentor/a? ¿Qué duración tendrá la mentoría? ¿Qué modalidades de reunión existen?</h2>
                    <div class="body">
                        <p>Las sesiones mínimas serán tres y podrán ser online o presenciales. En caso de acordar más dependerá de la disponibilidad de tu mentor/a y siempre que finalicen antes de los 3 meses desde que se iniciara la mentoría. </p>
                    </div>
                </article>



                <article class="faq" type="mentee">
                    <h2 @click="toggle">7. ¿Cómo accedo al certificado de participación?</h2>
                    <div class="body">
                        <p>Podrán obtener este certificado, tras finalizar la mentoría, quienes cumplan con estos tres
                            requisitos:</p>
                        <p>1. Haber cumplimentado el cuestionario de satisfacción de la mentoría.
                        </p>
                        <p>2. Haber alcanzado las tres reuniones/sesiones mínimas obligatorias.
                        </p>
                        <p>3. Y, haber superado la mentoría satisfactoriamente tras la evaluación de su mentor/a.
                        </p>
                        <p>Este certificado reconoce la participación del mentee en el mismo y podrá descargarse como un
                            diploma de participación en formato PDF desde el área privada del participante, apartado
                            <a href="https://gestionmentoring.uc3m.es/mis-mentorias" target="_blank">“Mis mentorías”</a>.
                        </p>
                    </div>
                </article>

                <article class="faq" type="mentee">
                    <h2 @click="toggle">8. ¿Puedo participar como mentee en varios programas de Mentoring
                        simultáneamente?</h2>
                    <div class="body">
                        <p>Si estas participando en un programa de Mentoring, hasta que no finalice la mentoría que tengas activa en el mismo, no podrás participar en otro programa diferente.</p>
                        <p>Solo se permite tener una mentoría activa, es decir, no podrás tener a varios mentores simultáneamente. Una vez que la mentoría haya llegado a su fin, puedes volver a solicitar otro mentor/a en el siguiente curso académico o bien en cualquiera de los programas activos de Mentoring que podrás consultar en tu área privada.</p>
                    </div>
                </article>

                <article class="faq" type="mentee">
                    <h2 @click="toggle">9. Una vez que finalice el período de mentoría, ¿qué debo hacer?</h2>
                    <div class="body">
                        <p>Es importante que cumplimentes la <strong>evaluación</strong> de la mentoría una vez que haya
                            finalizado. Para nosotros es muy importante conocer tu grado de satisfacción con el Programa
                            y saber en qué podemos mejorar. Puedes acceder a dicha evaluación desde tu área privada,
                            apartado <a href="https://gestionmentoring.uc3m.es/mis-mentorias" target="_blank">“Mis mentorías”</a> y seleccionando la mentoría finalizada.</p>
                    </div>
                </article>


            </div>

        </div>


    </section>

</template>


<script>
    export default {
        name: 'faqs',
        props : ['show'],
        methods: {
            toggle(event) {
                event.target.nextSibling.classList.toggle("open");
                event.target.classList.toggle("open");
            }
        }
    }
</script>