<template>
    <div class="wrapper -mentoring">

        <section class="encabezado -mentoring">

            <h1>Programa Mentoring<br>Alumni UC3m</h1>

            <div class="botones row-center">
                <a @click="fullscreen" class="play">
                    <img src="/img/play.svg" alt="Play">
                </a>
            </div>

        </section>

        <section class="texto-imagen -white">

            <div class="container row-start">

                <div class="contenedor-texto column-start">
                    <p>Los graduados y graduadas UC3M son profesionales altamente cualificados que conocen perfectamente la Universidad y quieren compartir sus valiosas experiencias profesionales y personales. Los mentees UC3M quieren tener una experiencia universitaria más intensa aprendiendo de quienes les han precedido.</p>
                    <p>El Programa Mentoring es el espacio donde el ejemplo y la orientación de los alumni potencia al máximo el talento de los estudiantes y egresados/as UC3M. Así, distintas generaciones comparten su conocimiento y refuerzan la idea de comunidad UC3M.</p>
                    <p>Los alumni estrechan sus relaciones con la Universidad potenciando el desarrollo de vocaciones y carreras profesionales.</p>
                    
                </div>

                <div class="contenedor-imagen">
                    <img src="/img/programa-mentoring.jpg" alt="">
                </div>
            </div>

        </section>

        <section class="tips-container">

            <h2 class="title">Beneficios</h2>
      
            <div class="container -xs row-start ais">        
              <article>
                <div class="icon">
                  <img src="/img/icon-flexibilidad.svg" alt="Flexibilidad">
                </div>
                <h3>Aprendizaje</h3>
                <p>Transferencia de conocimientos a través de la experiencia de profesionales cualificados.
                </p>
              </article>
              <article>
                <div class="icon">
                  <img src="/img/icon-networking.svg" alt="Networking">
                </div>
                <h3>Networking</h3>
                <p>Amplía tu red de contactos y genera nuevas oportunidades profesionales.</p>
              </article>
              <article>
                <div class="icon">
                  <img src="/img/icon-acompanamiento.svg" alt="Acompañamiento">
                </div>
                <h3>Apoyo</h3>
                <p>En la definición de objetivos profesionales e identificación de facilitadores y barreras para su consecución.</p>
              </article>
              <article>
                <div class="icon">
                  <img src="/img/icon-talleres.svg" alt="Talleres">
                </div>
                <h3>Talleres</h3>
                <p>Participación en talleres en el espacio Aula Mentoring.</p>
              </article>
              <article>
                <div class="icon">
                  <img src="/img/icon-habilidades.svg" alt="Habilidades">
                </div>
                <h3>Habilidades</h3>
                <p>Desarrollo de habilidades profesionales.</p>
              </article>
              <article>
                <div class="icon">
                  <img src="/img/icon-certificacion.svg" alt="Certificación">
                </div>
                <h3>Certificación</h3>
                <p>Reconocimiento de participación al superar las sesiones mínimas obligatorias.</p>
              </article>
            </div>
          </section>

        <slider-pasos></slider-pasos>

        <slider-testimonios></slider-testimonios>

        <section class="texto-imagen">

            <div class="container row-start aifs">

                <div class="contenedor-texto -full column-start">
                    <h2 class="title">Ayúdanos a difundir</h2>
                    <p>La Red de Mentores y Mentees conforma el altavoz más potente y numeroso para difundir este Programa. Puedes sumarte a su difusión descargándote este material o a través de las redes sociales.</p>
                    <a href="/img/ayudanos-a-difundir.jpg" download class="btn -skull">Descarga el material</a>
                    <nav class="row-start rrss">
                        <a :href="`http://twitter.com/intent/tweet?url=https://mentoringalumni.uc3m.es/&text=Únete al Programa Mentoring Alumni UC3M, un modelo de orientación profesional creado para impulsar el talento de estudiantes y egresados/as de la Universidad Carlos III de Madrid.`" target="_blank"><i class="fa fa-twitter"></i></a>
                        <a :href="`http://www.facebook.com/sharer.php?u=https://mentoringalumni.uc3m.es/&text=Únete al Programa Mentoring Alumni UC3M, un modelo de orientación profesional creado para impulsar el talento de estudiantes y egresados/as de la Universidad Carlos III de Madrid.`" target="_blank"><i class="fa fa-facebook"></i></a>
                        <a :href="`https://www.linkedin.com/sharing/share-offsite/?url=https://mentoringalumni.uc3m.es/&text=Únete al Programa Mentoring Alumni UC3M, un modelo de orientación profesional creado para impulsar el talento de estudiantes y egresados/as de la Universidad Carlos III de Madrid.`" target="_blank"><i class="fa fa-linkedin"></i></a>
                    </nav>
                </div>


            </div>

        </section>
        <faqs :show="['mentor','mentee']"></faqs>

        <section class="video-hide" v-if="showvideo">
          <a @click="showvideo = false" class="close">✕</a>
            <iframe class="video" id="video" src="https://www.youtube.com/embed/OWNLtlHvIlE?version=3&enablejsapi=1&autoplay=1&loop=1" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;
            picture-in-picture" allowfullscreen ></iframe>
        </section>
        

    </div>
</template>

<script>

    import slidertestimonios from '@/components/slider-testimonios-todos';
    import sliderpasos from '@/components/slider-pasos';
    import faqs from '@/components/faqs';


    export default {
        name: 'programa-mentoring',
        data() {
            return {
              showvideo : false
            }
        },
        methods : {
            fullscreen(){
              this.showvideo = true;
            },
        },
        components: {
            'slider-testimonios': slidertestimonios,
            'slider-pasos': sliderpasos,
            'faqs': faqs
        }
    }
</script>

<style lang="scss" scoped>
  .texto-imagen .contenedor-texto.-full{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title{
      width: 100%;
      text-align:center;
    }
    p{
      width: 100%;
      display: inline-block;
      text-align: center;
      margin: 0 auto;
    }
  }
</style>